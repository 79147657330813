<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">Зарегистрироваться</h1>
				<p class="text-lg">Оставить заявку на регистрацию учетной записи уровня администратора</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->

		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
			<template #title>
				<h5 class="font-semibold text-center">Зарегистрироваться</h5>
			</template>
			<div class="sign-up-gateways">
				<!-- TODO: add telegram login button once its deployed to a domain -->
				<!-- 
    			<a-button>
					<img src="images/logos/logos-facebook.svg" alt="">
				</a-button>
    			<a-button>
					<img src="images/logos/logo-apple.svg" alt="">
				</a-button>
    			<a-button>
					<img src="images/logos/Google__G__Logo.svg.png" alt="">
				</a-button>
				-->
			</div>
			<!-- TODO: telegram login -->
			<!--<p class="text-center my-25 font-semibold text-muted">Or</p> -->
			<a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit">
				<a-form-item class="mb-10">
					<a-input v-decorator="[
						'name',
						{ rules: [{ required: true, message: 'Пожалуйста введите ваше имя' }] },
					]" placeholder="Имя">
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Пожалуйста введите ваш Email' }] },
					]" placeholder="Email">
					</a-input>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Пожалуйса введите ваш пароль' }] },
					]" type="password" placeholder="Пароль">
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<!-- 					<a-checkbox v-decorator="[
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						},
					]">
						I agree the <a href="#" class="font-bold text-dark">Terms and Conditions</a>
					</a-checkbox> -->
				</a-form-item>
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						ЗАРЕГИСТРИРОВАТЬСЯ
					</a-button>
				</a-form-item>
			</a-form>
			<p class="font-semibold text-muted text-center">Уже есть учетная запись? <router-link to="/sign-in"
					class="font-bold text-dark">Войти</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>
import { notification } from 'ant-design-vue';
import axios from 'axios';


export default ({
	data() {
		return {
		}
	},
	beforeCreate() {
		// Creates the form and adds to it component's "form" property.
		this.form = this.$form.createForm(this, { name: 'normal_login' });
	},
	methods: {
		openNotification(placement, status, message) {
			const api = notification.open;
			api({
				message: status,
				description: message,
				placement
			});
		},
		// Submits account data
		async submitSignUpData(values) {
			try {
				let prequest = {
					name: values.name,
					email: values.email,
					password: values.password
				}
				const responce = await axios.post("api/v1/signup", prequest);
				console.log(responce);
				if (responce.data.status === 'success') {
					this.openNotification('topRight', "Успешная регистрация", "Успешно зарегистрировали пользователя " + values.name);
					this.$router.push({ name: 'Sing-In' });
				}
				else if (responce.data.status === 'exists') {
					this.openNotification('topRight', "Ошибка регистрации", "Пользователь с этим Email'ом уже существует");
				}
				else {
					this.openNotification('topRight', "Ошибка регистрации", "Произошла ошибка: " + responce.data.error);
				}
				console.log(responce.data);

			}
			catch (error) {
				console.log(error);
				this.openNotification('topRight', 'Ошибка передачи данных', 'Произошла ошибка в ходе передачи данных');
			}
		},
		// Handles input validation after submission.
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					console.log('Received values of form: ', values);
				}
				this.submitSignUpData(values);
			});
		},
	},
})

</script>

<style lang="scss">
</style>
